html {
  box-sizing: border-box;
  font-size: 16px;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Swift Neue", serif;
}

a, p, ul, ol, label, span, div {
  font-family: "Calibre", sans-serif;
}

/*
ol, ul {
  list-style: none;
}
*/

img {
  max-width: 100%;
  height: auto;
  image-rendering: pixelated;
}

a:link, a:visited {
    text-decoration: inherit;
    color: inherit;
    cursor: pointer;
}

.leaflet-control-layers-base {
  font-size: 1rem;
  line-height: 1.5;
}
.leaflet-control-layers-base::before {
  font-size: 1.5rem;
  line-height: 1.5;
  content: "Base Layers";
}

          .leaflet-container {
              width: 100%;
                height: 100%;
              }
          .leaflet-top {
              display: flex;
                flex-direction: row;
              }
          .leaflet-popup {
width: 400px;
          }
          .leaflet-container a.leaflet-popup-close-button {
            font-size: 50px;
            top: 5px;
            right: 5px;
            color: #8ACCFF;
          }
          .leaflet-popup-content-wrapper {
            padding: 0px;
            border: 1px solid #00256E;
border-radius: 5px;
  overflow: hidden;
          }
          div.leaflet-popup-content {
            margin: 0px;
          width: 100% !important;
  background-color: #F2F5FF !important;
display: flex;
  flex-direction: column;
          }
          div.leaflet-popup-content table {
            margin: 10px;
            width: initial;
          }
          .leaflet-control {
            margin-bottom: auto;
          }
@font-face {
  font-family: 'Swift Neue';
  src: local('Swift Neue'), url(./fonts/SwiftNeueLTPro-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Calibre';
  src: local('Calibre Regular'), url(./fonts/Calibre-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Calibre';
  src: local('Calibre Bold'), url(./fonts/Calibre-Semibold.ttf) format('truetype');
  font-weight: bold;
}

div.modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.pass-through {
  pointer-events: none !important;
}
